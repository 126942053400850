import { useState } from "react";
import "./styles.css";

import icon from "../../assets/icontel.png";

const CtaTel = () => {
  const [text, setText] = useState("(84) 99920-6237");

  return (
    <button id="ctaTel">
      <a href="tel:84999206237">
        <figure>
          <img src={icon} alt="Ícone do Telefone" />
        </figure>
        <p>{text}</p>
      </a>
    </button>
  );
};

export default CtaTel;
