import CtaTel from "../../components/CTATel";
import CtaWpp from "../../components/CTAWpp";
import "./styles.css";

const About = () => {
  return (
    <section id="about">
      <article className="about1">
        <div className="container">
          <h2 className="title">Conte com nossa equipe de especialistas</h2>

          <p className="text">
            Se precisar de ajuda jurídica em algum dos temas acima, fale agora
            mesmo com nossa equipe de advogados especialistas, via telefone ou
            WhatsApp:
          </p>

          <div className="ctas">
            <CtaWpp />
            <CtaTel />
          </div>
        </div>
      </article>

      <article className="about2">
        <div className="container">
          <h2 className="title">
            POR QUE CONTRATAR A HS ADVOCACIA ESPECIALIZADA?
          </h2>

          <p className="text">
            Sempre focados no melhor resultado para nossos clientes, oferecemos
            excelência no atendimento e resolução de demandas, sempre atuando
            com ética e profissionalismo.
          </p>

          <CtaWpp text={"Fale agora com o Advogado"} />
        </div>
      </article>

      <article className="mobile">
        <div className="container">
          <h2 className="title">
            POR QUE CONTRATAR A HS ADVOCACIA ESPECIALIZADA?
          </h2>

          <p className="text">
            Sempre focados no melhor resultado para nossos clientes, oferecemos
            excelência no atendimento e resolução de demandas, sempre atuando
            com ética e profissionalismo.
          </p>

          <CtaWpp text={"Fale agora com o Advogado"} />
        </div>
      </article>

      <article className="about3">
        <div className="container">
          <h2 className="title">
            Conte com HS ADVOCACIA ESPECIALIZADA para atuar em sua defesa
          </h2>

          <p className="text">Fale conosco via telefone ou WhatsApp:</p>

          <div className="ctas">
            <CtaWpp />
            <CtaTel />
          </div>
        </div>
      </article>
    </section>
  );
};

export default About;
