import CtaWpp from "../../components/CTAWpp";
import "./styles.css";

import header2_1 from "../../assets/header2_1.png";
import header2_2 from "../../assets/header2_2.png";
import header2_3 from "../../assets/header2_3.png";
import header2_4 from "../../assets/header2_4.png";
import header2_5 from "../../assets/header2_5.png";
import header2_6 from "../../assets/header2_6.png";
import header2_7 from "../../assets/header2_7.png";
import header2_8 from "../../assets/header2_8.png";

const Header = () => {
  return (
    <header id="header">
      <section className="header1">
        <article className="container">
          <h1 className="title">ADVOCACIA ESPECIALIZADA</h1>

          <p className="text">
            Somos Especialistas em Direito Bancário, Cível, Consumidor, Família
            Imobiliário, Previdenciário, Criminal e Médico.
          </p>

          <CtaWpp text={"Fale agora com o Advogado"} />
        </article>
      </section>

      <section className="header2">
        <article className="container">
          <h2 className="title">conheça nossas áreas de atuação</h2>

          <div className="gallery">
            <div className="item">
              <figure className="top">
                <img src={header2_1} alt="Direito de Família e Sucessões" />
              </figure>

              <div className="bottom">
                <p className="item_title">Direito de Família e Sucessões</p>

                <p className="item_text">
                  Guarda, Divórcio, União Estável, Casamento, Interdição,
                  Adoção, Pensão para Filhos e Cônjuge, Partilha de Bens,
                  Acordos Extrajudiciais, Inventário e Testamento.
                </p>
              </div>
            </div>

            <div className="item">
              <figure className="top">
                <img src={header2_2} alt="Direito do Consumidor" />
              </figure>

              <div className="bottom">
                <p className="item_title">Direito do Consumidor</p>

                <p className="item_text">
                  Inscrição Indevida SPC e Serasa, Descontos Indevidos em Conta
                  Salário e Poupança, Utilização de Dados Pessoais Indevida,
                  Corte Indevido de Água e Luz, Pedido de Religação de Água e
                  Luz e Compra de Produtos Vencidos.
                </p>
              </div>
            </div>

            <div className="item">
              <figure className="top">
                <img src={header2_3} alt="Direito Criminal" />
              </figure>

              <div className="bottom">
                <p className="item_title">Direito Criminal</p>

                <p className="item_text">
                  Furto, Roubo, Estelionato, Falsificação de Assinaturas e
                  Documentos, Drogas e Maria da Penha.
                </p>
              </div>
            </div>

            <div className="item">
              <figure className="top">
                <img src={header2_4} alt="Direito Imobiliário" />
              </figure>

              <div className="bottom">
                <p className="item_title">Direito Imobiliário</p>

                <p className="item_text">
                  Compra e Venda, Usucapião, Elaboração e Revisão Contratual e
                  Locação Residencial ou Comercial.
                </p>
              </div>
            </div>

            <div className="item">
              <figure className="top">
                <img src={header2_5} alt="Direito Cível" />
              </figure>

              <div className="bottom">
                <p className="item_title">Direito Cível</p>

                <p className="item_text">
                  Mudança de Nome, Reparação por Danos Morais e Materiais,
                  Reparação por Dano Estético, Uso Indevido de Imagem, Bloqueio
                  e Reativação de Redes Sociais, Cobrança de Valores e Golpes
                  nas Rede Sociais.
                </p>
              </div>
            </div>

            <div className="item">
              <figure className="top">
                <img src={header2_6} alt="Direito Bancário" />
              </figure>

              <div className="bottom">
                <p className="item_title">Direito Bancário</p>

                <p className="item_text">
                  Devolução de Cheques, Cobranças Indevidas, Renegociação de
                  Dívidas, Busca e Apreensão, Empréstimo e Bloqueio de Contas.
                </p>
              </div>
            </div>

            <div className="item">
              <figure className="top">
                <img src={header2_7} alt="Direito Médico" />
              </figure>

              <div className="bottom">
                <p className="item_title">Direito Médico</p>

                <p className="item_text">
                  Reajustes Abusivos, Erro Médico, Recusa de Tratamento Médico
                  Domiciliar, Reembolso de Despesas, Recusa de Tratamento para
                  Autistas e Auxílio Moradia Para Médicos.
                </p>
              </div>
            </div>

            <div className="item">
              <figure className="top">
                <img src={header2_8} alt="Direito Previdenciário" />
              </figure>

              <div className="bottom">
                <p className="item_title">Direito Previdenciário</p>

                <p className="item_text">
                  LOAS, Acidente de Trabalho e Doenças Ocupacionais,
                  Aposentadoria Por Idade, Aposentadoria Por Invalidez e Visão
                  Monocular.
                </p>
              </div>
            </div>
          </div>
        </article>
      </section>
    </header>
  );
};

export default Header;
